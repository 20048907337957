$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-navbutton").click(function () {
        $(".js-navbutton, .js-nav, .js-navoverlay").toggleClass("active");
        $(".js-header").toggleClass("navOpen");
        // $("body").toggleClass("freeze");
    });

    $(".js-nav svg").click(function () {
        $(this).toggleClass("active").next().slideToggle();
    })

    $(".js-nav li.active > svg").trigger("click");

    $(".js-subnav-button").mouseenter(function() {
        $(".js-subnav-button").parent().removeClass("navOpen");
        $(".js-subnav").removeClass("active");
        $(this).parents("ul").find("li.active").addClass("toggle");
        $(this).parent().addClass("navOpen");
        $(this).parents(".js-header").addClass("subNav");
        $(this).siblings(".js-subnav").addClass("active");
    })

    $(".header__nav").mouseleave(function() {
        $(".js-subnav-button").parent().removeClass("navOpen");
        $(this).parents(".js-header").removeClass("subNav");
        $(".js-subnav").removeClass("active");
        $(this).find("li.toggle").removeClass("toggle");
    })




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function () {
        $(this).toggleClass("active").siblings().slideToggle().parents(".js-accordion-item").siblings().find(".js-accordion-button").removeClass("active").siblings().slideUp();
    });

    $(".js-accordion-item:first-child .js-accordion-button").trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * image-accordion
    // *
    // *
    $(".js-image-accordion-button").click(function () {
        $(this).toggleClass("active").siblings().slideToggle().parents(".js-image-accordion-item").siblings().find(".js-image-accordion-button").removeClass("active").siblings().slideUp();

        let index = $(this).parents(".js-image-accordion-item").index();
        // $(this).parents(".js-image-accordion-wrapper").find(".js-image-accordion-image").eq(index).addClass("active").siblings().removeClass("active");
        $(this).parents(".js-image-accordion-wrapper").find(".js-image-accordion-image").eq(index).siblings().hide();
        $(this).parents(".js-image-accordion-wrapper").find(".js-image-accordion-image").eq(index).fadeIn();
    });

    $(".js-image-accordion-item:first-child .js-image-accordion-button").trigger("click");
    $(".js-image-accordion-item:first-child .js-image-accordion-button").trigger("click");
//
    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderImage
    // *
    // *
    var $sliderImage = $(".js-sliderimage");

    if ($sliderImage.length) {
        $sliderImage.each(function () {
            $(".js-sliderimage").slick({
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderNews
    // *
    // *
    var $sliderNews = $(".js-slidernews");

    if ($sliderNews.length) {
        $sliderNews.each(function () {
            $(this).slick({
                slidesToShow: 3,
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
                appendDots: $(this).parents('.js-slidernews-wrapper').find('.js-slidernews-dots'),
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 1,
                        }
                    },
                ]
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderProfile
    // *
    // *
    var $sliderProfile = $(".js-sliderprofile");

    if ($sliderProfile.length) {
        $sliderProfile.each(function () {
            $(".js-sliderprofile").slick({
                slidesToShow: 1,
                dots: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                speed: 1000,
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tabs
    // *
    // *
    $(".js-tab").click(function () {
        var index = $(this).index();
        $(this).parents(".js-tab-wrapper").find(".js-tab-text").eq(index).addClass("active").siblings().removeClass("active");
        $(this).addClass("active").siblings().removeClass("active")
    })

    $(".js-tab").eq(0).trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * clickMap
    // *
    // *
    function clickMap() {
            // Iterate over each element with the class "js-clickmap-button"
        $(".js-clickmap-button").each(function() {
            // Cache $(this) for better performance
            var $this = $(this);

            // Retrieve data attributes for desktop layout
            var width = $this.data("width");
            var height = $this.data("height");
            var top = $this.data("top");
            var left = $this.data("left");
            
            // Check if window width is less than or equal to 800 pixels
            if ($(window).width() <= 800) {
                // If true, retrieve data attributes for mobile layout
                width = $this.data("mobile-width");
                height = $this.data("mobile-height");
                top = $this.data("mobile-top");
                left = $this.data("mobile-left");
            }

            // Set CSS properties for the current element
            $this.css({
                "width": width + "%",
                "height": height + "%",
                "top": top + "%",
                "left": left + "%"
            });
        });
    }

    $(window).on("resize", function () {
        clickMap();
    });

    clickMap()

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 30) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});


$(window).on("load", function () {
    $(".js-clickmap-button").each(function() {
        // Cache $(this) for better performance
        var $this = $(this);

        // Retrieve data attributes for desktop layout
        var width = $this.data("width");
        var height = $this.data("height");
        var top = $this.data("top");
        var left = $this.data("left");
        
        // Check if window width is less than or equal to 800 pixels
        if ($(window).width() <= 800) {
            // If true, retrieve data attributes for mobile layout
            width = $this.data("mobile-width");
            height = $this.data("mobile-height");
            top = $this.data("mobile-top");
            left = $this.data("mobile-left");
        }

        // Set CSS properties for the current element
        $this.css({
            "width": width + "%",
            "height": height + "%",
            "top": top + "%",
            "left": left + "%"
        });
    });
});
